import React, { useEffect } from 'react'
import './metricLoader.css'
import { useState} from 'react'
import { TextField, InputAdornment, FormControlLabel, RadioGroup, FormLabel, Radio} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import {useMutation, useQuery} from "@tanstack/react-query"
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import InventoryIcon from '@mui/icons-material/Inventory';
import OutboundIcon from '@mui/icons-material/Outbound';
import WorkIcon from '@mui/icons-material/Work';
import CheckIcon from '@mui/icons-material/Check';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PublicIcon from '@mui/icons-material/Public';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import DownloadingIcon from '@mui/icons-material/Downloading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useRef } from 'react';
import NewSideCube from '../Cube/NewSideCube';
import { Box } from "@mui/material";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DatasetIcon from '@mui/icons-material/Dataset';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';

function MetricLoader({plant, user, countMetrics, countRCAs}) {
  axios.defaults.withCredentials = true
  //get environment
  const host = window.location.hostname
//Posts and Gets ----------------------------------------------------------------------------------------------------------------------
//does auth need to be none for post options method as preflight does not contain credentials? 
    //Bring in Metrics from Database
    const getDbInfo = async() => {
      if(plant.length === 0){
        return null
      }
      else{
        //conditional statement to make sure plant has been selected by user
        const res = await axios.get(`https://api.${host}/db?plant=${plant}&date=${queryDate}`, {withCredentials: true});
        console.log(res)
        //to sort numerical and not lexicographical on 1st index of array
        setNewMetrics(res.data.sort((a,b)=>a[0]-b[0]))
        return res;
        
      }
  

        

    }

    //stoplight
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const colors = ['#AAFF00', '#FF7276', '#FFD580'];
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
      }, 2000); // Change color every 2 seconds
  
      return () => clearInterval(timer); // Cleanup timer on component unmount
    }, [colors.length]); // Include colors.length as a dependency
    //Post Inputs Back to Database
    const sendServerInfo = async(element) =>{
        const postRes = await axios.post(`https://api.${host}/dbpost`, {
            "description": metrics[element].description,
            "metric": metrics[element].metric,
            "plant": plant,
            "date": queryDate, 
            "user": user
        }, {withCredentials: true});
        console.log(postRes)
        return postRes;
    }
    //Posts Calculated Metrics Back to Database
    const sendServerInfoCalc = async(dict)=>{
        const postRes = await axios.post(`https://api.${host}/dbpost`, {
          "description": dict.desc,
          "metric": dict.amount === "" ? "" : (dict.amount / 100).toFixed(4),
          "plant": plant,
          "date": queryDate, 
          "user": user
        },{withCredentials: true});
        console.log(postRes)
        return postRes;
      
    }
    //Posts RCA Root Info Back to Database
    const sendRCAInfo = async(element) =>{
        console.log(element)
        const postRca = await axios.post(`https://api.${host}/rca`, {
          "description": element,
          "rca": metricRcaDict[element],
          "plant": plant,
          "date": queryDate
         },{withCredentials: true});
         console.log(postRca)
        return postRca;
    }
    //Posts RCA Action Info Back to Database 
    const sendRCAActionInfo = async(element) =>{
        console.log(element)
        const postRca = await axios.post(`https://api.${host}/action`,{
          "description": element,
          "rca": metricRcaActionDict[element],
          "plant": plant,
          "date": queryDate
         },{withCredentials: true});
         console.log(postRca)
        return postRca;
    }
    
//Tanstack Queries ----------------------------------------------------------------------------------------------------------------------
    const dbQuery = useQuery({
        queryKey: ["metrics"],
        queryFn: getDbInfo
    })
    const postMutation = useMutation({
        mutationFn: sendServerInfo,
        onSuccess: ()=>{
          getDbInfo()
        
        }
    },
    )
    const calcMutation = useMutation({
        mutationFn: sendServerInfoCalc,
        onSuccess: ()=>{
          getDbInfo()
        }

    },
    )
    const rcaMutation = useMutation({
        mutationFn: sendRCAInfo,
        onSuccess: ()=>{
          getDbInfo()
        }
    },
    )
      
    const rcaActionMutation = useMutation({
        mutationFn: sendRCAActionInfo,
        onSuccess: ()=>{
          getDbInfo()
        }
    },
    )

//Date Calculation Info ----------------------------------------------------------------------------------------------------------------------
    const date = new Date();
    const isFirstDay = date.getDate() === 1; 
    let day = date.getDate(); 
    let month = date.getMonth() + 1;
    let year = date.getFullYear()
    let queryMonth = 0;
    if (month === 1){
        year = year-1
        queryMonth = 12
    }
    else if(month<11){
        queryMonth = "0" + (month-1).toString()
    }
    else{
        queryMonth = month-1
    }
    const queryDate = `${year}-${queryMonth}-01` 
    const dateDict = {1: "December", 2: "January", 3: "February", 4: "March", 5: "April", 6: "May", 7: "June", 8: "July", 9: "August", 10: "September", 11: "October", 12: "November"}

    function getBusinessDayNumber(year, month, dayOfMonth) {
      let businessDayNumber = 0;
      
      for (let day = 0; day < dayOfMonth; day++) {
        const date = new Date(year, month - 1, day + 1); // Create a date object for the current day
        const dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
        
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Check if it's a weekday
          businessDayNumber++;
        }
      }
      
      return businessDayNumber;
    }
    const businessDayNumber = getBusinessDayNumber(year, month, day)    

//UseState Variables and Initial Dictionaries ----------------------------------------------------------------------------------------------------------------------
    const [newMetrics, setNewMetrics] = useState([])
    const [currPlant, setCurrPlant] = useState("")
    const [currCategory, setCurrCategory] = useState(0)
    const [currYear, setCurrYear] = useState(year)
    const [numOfMetrics, setNumOfMetrics] = useState(0)
    const [numOfRcas, setNumOfRcas] = useState(0)
    const [totalMetCount, setTotalMetCount] = useState(0)
    //universal calc use states..can adjust arrays to as many as you need
    // Define the initial state arrays
    const [calcAmounts, setCalcAmounts] = useState(Array(10).fill(""))
    const [calcConvAmount, setCalcConvAmount] = useState(Array(10).fill(""))
    const [calcSwitchAmount, setCalcSwitchAmount] = useState(Array(10).fill(""))
    const [backSpacedCalc, setBackSpacedCalc] = useState([])
    const [isOpen, setIsOpen] = useState(true);
   
    //const [lastCalc, setLastCalc] = useState("")
    //Page Disabled
    const [disabled, setDisabled] = useState(false)
    let y = numOfRcas
    let z = numOfMetrics
    let t = totalMetCount

    //need dictionary for material ui icons
    const mui = {"Finance" :<AttachMoneyIcon/>, "HR": <PeopleIcon/>, "HSE": <HealthAndSafetyIcon/>, "Inventory": <InventoryIcon/>, "Outbound": <OutboundIcon/>,
    "Productivity": <WorkIcon/>, "Quality": <CheckIcon/>, "Safety": <SafetyCheckIcon/>, "Service": <DesignServicesIcon/>, "Sustainability": <PublicIcon/>,
    "Transportation": <LocalShippingIcon/>, "Controls": <ControlCameraIcon/>}

    //plant dictionary
    const plantDictionary = {"JP04": "Kawasaki - Sankyu","Argent": "Argentina","CN03": "Wujiang - Boyol","CN10": "Shanghai - TingTong LX","DE20": "Weilbach","IT09": "Liscate","KSA": "KSA",
      "NA61": "3OVO","NA65": "DC 3PL Mexico","NA75": "North Brunswick","PL05": "Bielsko","Taiwan": "Taiwan - Focus","8002": "Brasil Goiania","8006": "Brasil Duque de Caxias","TH05": "Bangkok - LDS",
      "CN18": "Shanghai - Tingtong CB","AE02": "Dubai UAE","AE03": "JAFZA","AU10": "Sydney - Linfox CB/LX","ES09": "Barcelona","HK02": "Hong Kong - Bollore","MY01": "Malaysia Toll","NA29": "Riverside",
      "NA33": "Catano","SG13": "Singapore - Toll","DE21": "Dueren","UK27": "Bournemouth","NA53": "Sanford", "Korea": "Korea", "NA19": "Sanford-Factory", "NA21": "Hunt Valley - Factory", "C002": "Granollers - Factory",
      "CU05": "Ashford - Factory", "BRAF": "Goiania - Factory", "LF02": "Chartres - Factory", "MC147": "Monaco - Factory", "NA46": "West Rock", "SA01": "Saudi Arabia", "APAC": "APAC"
    };
    

    //dictionaries and arrays for universal calculations 
    const universalCalc = []
    const convertCalc = []
    const switchCalc = []
    const universalCalcInpDict = {}
    const universalCalcDict = {}
    const universalAttributes = {}

    //lower goals array needs to be set on initial pass
    const lowerGoals = []

    //display on day 5 or later array
    const dispFive = []
    const showDisabled = []

    //x is the metric count
    let x = 0
    //total metric count
    let total = 0
    //rcaCount is the rca count
    let rcaCount = 0

    const containerRef = useRef(null)


//Use Effect Hooks -------------------------------------------------------------------------------------------------------------------------
    //Designed to only run on initial render
    useEffect(()=>{
        if(month === 1){
        setCurrYear(year)
        }
        else if(month ===2){
        setCurrYear(year)
    
        }
        else{
        setCurrYear(year)
        }
    
    }, [month, year])
    //Will disable all text fields based on date of month
    useEffect(()=>{
        if(day>31){
          setDisabled(true)
        }
    }, [day])
    //Designed to be triggered by dependent variable

    




//Functions -------------------------------------------------------------------------------------------------------------------------
    //function to update Category on user click/arrow click

    const handleClose = () => {
    setIsOpen(false);
  };

    function updateCat(category){
        let newCat = category.category
        setCurrCategory(newCat)
        if (currCategory !== newCat){
          Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
            );
        }
        
        scrollToTop();
        
    }

    //arrow on click event for category change
    function moveCat(direction){
        if(direction === "plus"){
        let index = categoryArray.indexOf(currCategory)
        if(index+1 <categoryArray.length){
            updateCat({category: categoryArray[index+1]})
        }
        else{
            updateCat({category: categoryArray[0]})
        }

        }
        else{
        let index = categoryArray.indexOf(currCategory)
        if(index-1 >-1){
            updateCat({category: categoryArray[index-1]})
        }
        else{
            updateCat({category: categoryArray[categoryArray.length-1]})
        }

        }
        
    }
    
    //When a text field loses focus
    const handleBlur = input => e => {
            const element = input
            if(metrics[element].description === input){
              let numWithoutCommas = e.target.value.replace(/,/g,'')
              let metricWithoutCommas = (metrics[element].metric).toString().replace(/,/g,'')
                if(parseFloat(metricWithoutCommas) !== parseFloat(numWithoutCommas) && (metricWithoutCommas !== numWithoutCommas)){
                    if(numWithoutCommas.length < 15){
                        metrics[element].metric = numWithoutCommas
                        if (numWithoutCommas.length !== 0){
                          if(metrics[element].adorn==='%'){
                            metrics[element].metric = (numWithoutCommas/100).toFixed(4)
                          }
                        }
                       
                        //Post to Database after it has been confirmed new input and meets all requirements

                        postMutation.mutate(element)

                    }
                    //RCA CHECK
                    let goalWithoutCommas = metrics[element].goal.replace(/,/g,'')
                    let r12WithoutCommas = metrics[element].r12m.replace(/,/g,'')
                    let metricEl = parseFloat((metrics[element].metric))
                    let metricGoal = parseFloat((goalWithoutCommas))
                    let metricR12 = parseFloat(r12WithoutCommas)
                    if(lowerGoals.includes(metrics[element].description)){
                      if(metricEl > metricGoal){
                        metrics[element].error = true
                        metricErrorDict[metrics[element].description] = true
                        metrics[element].help = "Populate RCA"
                        metrics[element].disabled = false
                        if(metrics[element].rcaRoot.length <1 || metrics[element].rcaAction.length <1){
                          rcaCount+=1
                        }
                      }
                    else if(metrics[element].adorn === '%' && metricEl > 1){
                      metrics[element].help = "Over 100 Error"
                      metrics[element].error = true
                      metricErrorDict[metrics[element].description] = true
                    }
                    else{
                        metrics[element].error = false
                        metrics[element].help = " "
                        metrics[element].disabled = true

                    }


                    }
                    else{
                      if(metricEl < metricGoal){
                        metrics[element].error = true
                        metricErrorDict[metrics[element].description] = true
                        metrics[element].help = "Populate RCA"
                        metrics[element].disabled = false
                        if(metrics[element].rcaRoot.length <1 || metrics[element].rcaAction.length <1){
                          rcaCount+=1
                        }
                      }
                      else if(metrics[element].adorn === '%' && metricEl > 1){
                        metrics[element].help = "Over 100 Error"
                        metrics[element].error = true
                        metricErrorDict[metrics[element].description] = true
                      }
                    else{
                        metrics[element].error = false
                        metrics[element].help = " "
                        metrics[element].disabled = true

                    }

                    }
                    if(Math.abs((metricEl-metricR12)/metricR12)*100 >= 15){
                      metrics[element].help += " -15% Deviation from 12 mth avg"
                    }
                    


            }
            

        }


    return{
        ...metrics
    }
    };

    //handle change for radio buttons yes no
    const handleChange = input => e => {
        metricDict[input] = e.target.value
        const element = input
          let metricString = metrics[element].description
          if(metricString === input){
            if((metrics[element].metric) !== (e.target.value)){
              //safeguarding against passing back an empty string
              if(e.target.value.length !== 0){
                if (e.target.value === 'Yes'){
                  metrics[element].metric = -1

                }
                else if(e.target.value === 'No'){
                  metrics[element].metric = 1

                }
                
                postMutation.mutate(element)
              }
              //RCA Check
                    let metricEl = parseFloat((metrics[element].metric))
                    let metricGoal = ""
                    if(metrics[element].goal === "Yes"){
                      metricGoal = -1
                    }
                    else if(metrics[element].goal === "No"){
                      metricGoal = 1
                    }
                    if(lowerGoals.includes(metrics[element].description)){
                      if(metricEl > metricGoal){
                        metrics[element].error = true
                        metricErrorDict[metrics[element].description] = true
                        metrics[element].help = "Populate RCA"
                        metrics[element].disabled = false
                        if(metrics[element].rcaRoot.length <1 || metrics[element].rcaAction.length <1){
                          rcaCount+=1
                        }
                      }
                    else{
                        metrics[element].error = false
                        metrics[element].help = " "
                        metrics[element].disabled = true

                    }


                    }
                    else{
                      if(metricEl < metricGoal){
                        metrics[element].error = true
                        metricErrorDict[metrics[element].description] = true
                        metrics[element].help = "Populate RCA"
                        metrics[element].disabled = false
                        if(metrics[element].rcaRoot.length <1 || metrics[element].rcaAction.length <1){
                          rcaCount+=1
                        }
                      }
                    else{
                        metrics[element].error = false
                        metrics[element].help = " "
                        metrics[element].disabled = true

                    }

            }
           
            
          }
        
      }};

    //handle RCA Root Blur
    const handleRcaBlur = input => e =>{
        const str = `${e.target.value}`
        //remove apostrophes or special characters that may impact the query
        let rcaRes = str.replace(/[^a-zA-Z0-9., ]/g, '')
        if(metricRcaDict[input] !== (e.target.value)){
        //safeguarding against passing back an empty string
        if(e.target.value.length !== 0 & e.target.value.length < 500){
            metricRcaDict[input] = rcaRes
            rcaMutation.mutate(input)
        }
        }  
    }

    //handle RCA Action Blur
    const handleRcaActionBlur = input => e =>{
        const str = `${e.target.value}`
        //remove apostrophes or special characters that may impact the query
        let rcaRes = str.replace(/[^a-zA-Z0-9., ]/g, '')
        if(metricRcaActionDict[input] !== (e.target.value)){
          //safeguarding against passing back an empty string
          if(e.target.value.length !== 0 & e.target.value.length < 500){
            metricRcaActionDict[input] = rcaRes
            rcaActionMutation.mutate(input)
          }
        } 
      }

    //scroll to top function
    function scrollToTop() {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    


     

//Initial render and subsequent rerender variables------------------------------------------------------------------------------------
    //array of categories for this array populate divs with category name 
    let categoryArray = []
    //dictionary so we can pull whether category is complete or not and update styling accordingly 
    let categoryCompleted = {}
    //non completed categories array
    let nonCompleteCats = []
    //missing mets per category
    let missingMetsCat = {}
    //total mets per category
    let totalMetsCat = {}
    let metrics = {}
    let yesNo = []
    let metricDict = {}
    let metricErrorDict = {} //storing the errors with the corresponding description key
    let metricLastDict = {} //storing last months metrics
    let metricHelpDict = {} //storing the helper text for the metrics
    let metricGoalDict = {} //storing the average for the dictionary
    let metricAdornDict = {} //storing the adornment for each dictionary
    let metricRcaDict = {} //storing the rca for each metric
    let metricRcaActionDict = {} //storing the action rca for each metric
    let metricDisabled = {} //storing if RCA is enabled or not
    let metricMultiLine = {}//storing if RCA is multiline or not
    let metricInfoDict = {} //storing information of how metric is calculated
    let metricR12Dict = {} //dictionary for 12 month avg
    //acceptable input array
    let acceptable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',']


//Initial render and subsequent rerenders----------------------------------------------------------------------------------------------
    //Keep tabs on renders
    console.log("rendered metricLoader")
    //check if we are on the same plant if not empty out all front end inputs
    if(plant!==currPlant){
        getDbInfo()
        setCurrPlant(plant)
        setCurrCategory(0)
        //resetting states on plant change
        setCalcAmounts(Array(10).fill(""))
        setCalcConvAmount(Array(10).fill(""))
        setCalcSwitchAmount(Array(10).fill(""))
        setBackSpacedCalc([])
        Array.from(document.querySelectorAll("input")).forEach(
          input => (input.value = "")
          );

        
    }
    //Building out the metric dictionary based on the query of new Metrics
    for(let element in newMetrics){
      let currentMetric = newMetrics[element]
    //have to make any undefined or null values an empty string so your input value in jsx isn't going from undefined to defined (causes controlled component errors)
      if(currentMetric[7] === undefined || currentMetric[7] === null){
        currentMetric[7] = ""
      }
      if(currentMetric[8] === undefined || currentMetric[8] === null){
        currentMetric[8] = ""
      }
      //goal
      if(currentMetric[13] === undefined || currentMetric[13] === null){
        currentMetric[13] = ""
      }
      //calctype
      if(currentMetric[19] === undefined || currentMetric[19] === null){
        currentMetric[19] = ""
      }
       //dividend
       if(currentMetric[20] === undefined || currentMetric[20] === null){
        currentMetric[20] = ""
      }
      //divisor
      if(currentMetric[21] === undefined || currentMetric[21] === null){
        currentMetric[21] = ""
      }
      //calculated
      if(currentMetric[22] === undefined || currentMetric[22] === null){
        currentMetric[22] = ""
      }
      //rcaRoot
      if(currentMetric[24] === undefined || currentMetric[24] === null){
        currentMetric[24] = ""
      }
      //rcaAction
      if(currentMetric[25] === undefined || currentMetric[25] === null){
        currentMetric[25] = ""
      }
      //site User
      if(currentMetric[27] === undefined || currentMetric[27] === null){
        currentMetric[27] = ""
      }
      //r12
      if(currentMetric[28] === undefined || currentMetric[28] === null){
        currentMetric[28] = ""
      }
      //null description
      if(currentMetric[6] === undefined || currentMetric[6] === null){
        currentMetric[6] = "NotRealMetric"
      }
      let key = currentMetric[6]
      //build main dictionary
      metrics[key] = {
        'order': currentMetric[0],
        'plant': currentMetric[4],
        'plantName': currentMetric[5],
        'category': currentMetric[10],
        'description': currentMetric[6],
        'metric': currentMetric[7],
        'lastMetric': currentMetric[8],
        'adorn': currentMetric[12],
        'error': 'false',
        'help': " ",
        'info': `Data Source: ${currentMetric[15]} || Definition: ${currentMetric[16]}`,
        'rcaRoot': currentMetric[24],
        'rcaAction': currentMetric[25], 
        'goal': currentMetric[13],
        'siteUser': currentMetric[27],
        'r12m': currentMetric[28],
        'disabled': true
      }
      //YesNO Array Buildout
      if(currentMetric[12] === 'Yes/No'){
        yesNo.push(currentMetric[6])
        if(currentMetric[7]=== "-1" || currentMetric[7] === -1){
          metrics[key].metric = 'Yes'
        }
        if(currentMetric[13] === "-1" || currentMetric[13] === "-1.0" || currentMetric[13] === -1 || currentMetric[13] === -1.0){
          metrics[key].goal = 'Yes'
        }
        if(currentMetric[8] === "-1" || currentMetric[8] === "-1.0" || currentMetric[8] === -1 || currentMetric[8] === -1.0){
          metrics[key].lastMetric = 'Yes'
        }
        
        if(currentMetric[7]==="1" || currentMetric[7] === 1){
          metrics[key].metric = 'No'
        }
        if(currentMetric[8]=== "1" || currentMetric[8] === 1){
          metrics[key].lastMetric = 'No'
        }
        if(currentMetric[13] === "1" || currentMetric[13] === "1.0" || currentMetric[13] === 1){
          metrics[key].goal = 'No'
        }
    
      }
      //overunderarray
      if(currentMetric[14] === 'Over'){
        lowerGoals.push(currentMetric[6])
      }
      //transforming percentage numbers to be whole numbers for app
      if(currentMetric[12] === '%'){
        if(currentMetric[8]!== ""){
          metrics[key].lastMetric = currentMetric[8]*100
        }
        if(currentMetric[13]!== ""){
          metrics[key].goal = (currentMetric[13]*100).toFixed(2)
        }
        if(currentMetric[7]!== ""){
          metrics[key].metric = (currentMetric[7]*100).toFixed(2)
        }
        //need incoming data to be decimal format not percentage like what I'm sending back
        if(currentMetric[8]!== ""){ 
          metrics[key].lastMetric = (currentMetric[8]*100).toFixed(2)
        }
        //changing the r12 percentages to whole numbers for comparison
        if(currentMetric[28]!== ""){ 
          metrics[key].r12m = (currentMetric[28]*100).toFixed(2)
        }
      }
      //average so needs to be fixed to two decimals
      else if(currentMetric[12] !== '%' && currentMetric[12] !== 'Yes/No'){
        if(currentMetric[28] !== ""){
          let numNoCommas = (currentMetric[28]).toLocaleString('en-US', { style: 'decimal' })
          metrics[key].r12m = numNoCommas.replace(/\.\d*/, '')
        }
        if(currentMetric[7] !== ""){
          metrics[key].metric = (currentMetric[7]).toLocaleString('en-US', { style: 'decimal' });
        }
        if(currentMetric[13] !== ""){
          metrics[key].goal = (currentMetric[13]).toLocaleString('en-US', { style: 'decimal' });
        }
        if(currentMetric[8] !== ""){
          metrics[key].lastMetric = currentMetric[8].toLocaleString('en-US', { style: 'decimal' });
        }

        
        
      }
      //check if calculated using calc div divisor parent build out necessary arrays/objects
      if(currentMetric[22] === 'Yes' && currentMetric[19].length === 0){
        universalCalc.push(currentMetric[6])
      }
      if (currentMetric[22] === 'Yes' && currentMetric[19] === 'Convert'){
        convertCalc.push(currentMetric[6])

      }
      if (currentMetric[22] === 'Yes' && currentMetric[19] === 'Switch'){
        switchCalc.push(currentMetric[6])

      }
      if(currentMetric[20] === 'Yes' || currentMetric[21] === 'Yes'){
        universalCalcInpDict[currentMetric[6]] = ""
      }
      if(currentMetric[20] === 'Yes'){
        if(universalAttributes[currentMetric[23]] === undefined){
          universalAttributes[currentMetric[23]] = {'dividend': "", 'divisor': ""}
          universalAttributes[currentMetric[23]].dividend = currentMetric[6]
        }
        else{
          universalAttributes[currentMetric[23]].dividend = currentMetric[6]
        }
        
      }
      if(currentMetric[21] === 'Yes'){
        if(universalAttributes[currentMetric[23]] === undefined){
          universalAttributes[currentMetric[23]] = {'dividend': "", 'divisor': ""}
          universalAttributes[currentMetric[23]].divisor = currentMetric[6]
        }
        else{
          universalAttributes[currentMetric[23]].divisor = currentMetric[6]
        }
        
      }
      if(currentMetric[19] === 'Display5'){
        showDisabled.push(currentMetric[6])
        if(businessDayNumber <1){
          dispFive.push(currentMetric[6])

        }
        
      }
      


    }
    

  

    //Run through metric Dictionary to establish values
 for (let element in metrics) {
  let metric = metrics[element]; // Cache object reference

  if (!metric.description || metric.description === "NotRealMetric" || dispFive.includes(metric.description)) {
    continue;
  }
  total += 1
  totalMetsCat[metric.category] = (totalMetsCat[metric.category] || 0) + 1;

  if (!categoryArray.includes(metric.category)) {
    categoryArray.push(metric.category);
    categoryCompleted[metric.category] = "Yes";
  }

  if (!metric.metric || metric.metric.length === 0 || metric.metric.toString().length === 0) {
    x += 1;
    nonCompleteCats.push(metric.category);
    missingMetsCat[metric.category] = (missingMetsCat[metric.category] || 0) + 1;
  } else {
    let metricNoCommas = metric.metric.toString().replace(/,/g, '');
    let goalWithoutCommas = metric.goal.toString().replace(/,/g, '');
    let r12WithoutCommas = metric.r12m.toString().replace(/,/g, '');
    let metricEl = parseFloat(metricNoCommas);
    let metricGoal = parseFloat(goalWithoutCommas);
    let metricR12 = parseFloat(r12WithoutCommas);

    if (metric.metric === "Yes") {
      metricEl = -1;
    } else if (metric.metric === "No") {
      metricEl = 1;
    }

    if (metric.goal === "Yes") {
      metricGoal = -1;
    } else if (metric.goal === "No") {
      metricGoal = 1;
    }

    if (lowerGoals.includes(metric.description)) {
      if (metricEl > metricGoal) {
        metric.error = true;
        metric.help = "Populate RCA";
        metric.disabled = false;

        if (metric.rcaRoot.length < 1 || metric.rcaAction.length < 1) {
          rcaCount += 1;
        }
      }
    } 
    else if(metrics[element].adorn === '%' && metricEl > 100){
      metric.error = true;
      metric.help = "Over 100 Error";

    }
    else {
      if (metricEl < metricGoal) {
        metric.error = true;
        metric.help = "Populate RCA";
        metric.disabled = false;

        if (metric.rcaRoot.length < 1 || metric.rcaAction.length < 1) {
          rcaCount += 1;
        }
      }
      else if(metrics[element].adorn === '%' && metricEl > 100){
        metric.error = true;
        metric.help = "Over 100 Error";
  
      }

    }
    

    if (Math.abs((metricEl - metricR12) / metricR12) * 100 >= 15) {
      metric.help += " - 15% Dev. from avg";
    }
  }

  if (Object.keys(universalCalcInpDict).includes(metric.description)) {
    if (metric.metric !== "") {
      let metricNoCommas = metric.metric.toString().replace(/,/g, '');
      if (metricNoCommas !== universalCalcInpDict[metric.description]) {
        universalCalcInpDict[metric.description] = metricNoCommas;
      }
    }
  }

  if (universalCalc.includes(metric.description)) {
    universalCalcDict[metric.description] = ((universalCalcInpDict[universalAttributes[metric.description].dividend] / universalCalcInpDict[universalAttributes[metric.description].divisor]) * 100).toFixed(2);
    let newValue = ((universalCalcInpDict[universalAttributes[metric.description].dividend] / universalCalcInpDict[universalAttributes[metric.description].divisor]) * 100).toFixed(2);

    if (metric.metric !== newValue) {
      let index = universalCalc.indexOf(metric.description);
      if (index >= 0) {
        if (calcAmounts[index] !== newValue) {
          setCalcAmounts(prevState => {
            const newState = [...prevState];
            newState[index] = newValue;
            return newState;
          });
        }
      }
    }
  }

  if (convertCalc.includes(metric.description)) {
    universalCalcDict[metric.description] = (((universalCalcInpDict[universalAttributes[metric.description].divisor] - universalCalcInpDict[universalAttributes[metric.description].dividend]) / universalCalcInpDict[universalAttributes[metric.description].divisor]) * 100).toFixed(2);
    const newValue = (((universalCalcInpDict[universalAttributes[metric.description].divisor] - universalCalcInpDict[universalAttributes[metric.description].dividend]) / universalCalcInpDict[universalAttributes[metric.description].divisor]) * 100).toFixed(2);
    if (metric.metric !== newValue) {
      const index = convertCalc.indexOf(metric.description);
      if (index >= 0) {
        const updatedCalcConvAmount = [...calcConvAmount];
        if (updatedCalcConvAmount[index] !== newValue) {
          console.log("Use State Change")
          setCalcConvAmount(prevState => {
            const newState = [...prevState];
            newState[index] = newValue;
            return newState;
          });
        }
      }
    }
  }

  if (switchCalc.includes(metric.description)) {
    let dividend = Math.min(universalCalcInpDict[universalAttributes[metric.description].dividend], universalCalcInpDict[universalAttributes[metric.description].divisor]);
    let divisor = Math.max(universalCalcInpDict[universalAttributes[metric.description].dividend], universalCalcInpDict[universalAttributes[metric.description].divisor]);
    universalCalcDict[metric.description] = ((dividend / divisor) * 100).toFixed(2);
    const newValue = ((dividend / divisor) * 100).toFixed(2);

    if (metric.metric !== ((dividend / divisor) * 100).toFixed(2)) {
      const index = switchCalc.indexOf(metric.description);
      if (index >= 0) {
        if (calcSwitchAmount[index] !== ((dividend / divisor) * 100).toFixed(2)) {
          setCalcSwitchAmount(prevState => {
            const newState = [...prevState];
            newState[index] = newValue;
            return newState;
          });
        }
      }
    }
  }
}

    

    //update use states for calculated metrics
    //console.log('calcAmount', calcAmount, 'lastCalc', lastCalc)


    //Real time display and calculation of calculated metrics
    for(let element in metrics){
      let number = metrics[element].metric
      if(universalCalc.includes(metrics[element].description)){
        if(number !== ((universalCalcInpDict[universalAttributes[metrics[element].description].dividend]/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2)& 
      isFinite(((universalCalcInpDict[universalAttributes[metrics[element].description].dividend]/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2))===true){
        let amount = ((universalCalcInpDict[universalAttributes[metrics[element].description].dividend]/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2)
        let index = universalCalc.indexOf(metrics[element].description)
        if(index >= 0){
          if (amount !== calcAmounts[index] ){
            console.log(backSpacedCalc)
            let updatedList = [...backSpacedCalc]
            updatedList = updatedList.filter(item => item !== metrics[element].description)
            setBackSpacedCalc(updatedList);
            calcMutation.mutate({desc: metrics[element].description, amount: amount})
            
          }   
        }
        
        
      }
      

      }
      else if(convertCalc.includes(metrics[element].description)){
        if(number !== (((universalCalcInpDict[universalAttributes[metrics[element].description].divisor]-universalCalcInpDict[universalAttributes[metrics[element].description].dividend])/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2)&
        isFinite((((universalCalcInpDict[universalAttributes[metrics[element].description].divisor]-universalCalcInpDict[universalAttributes[metrics[element].description].dividend])/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2))===true){
          let amount = (((universalCalcInpDict[universalAttributes[metrics[element].description].divisor]-universalCalcInpDict[universalAttributes[metrics[element].description].dividend])/universalCalcInpDict[universalAttributes[metrics[element].description].divisor])*100).toFixed(2)
          let index = convertCalc.indexOf(metrics[element].description)
        if(index >= 0){
          if (amount !== calcConvAmount[index] ){
            console.log(backSpacedCalc)
            let updatedList = [...backSpacedCalc]
            updatedList = updatedList.filter(item => item !== metrics[element].description)
              setBackSpacedCalc(updatedList);
              calcMutation.mutate({desc: metrics[element].description, amount: amount})

            
          }   
        }


        }

      }
      else if(switchCalc.includes(metrics[element].description)){
        let dividend = Math.min(universalCalcInpDict[universalAttributes[metrics[element].description].dividend],universalCalcInpDict[universalAttributes[metrics[element].description].divisor] )
        let divisor = Math.max(universalCalcInpDict[universalAttributes[metrics[element].description].dividend],universalCalcInpDict[universalAttributes[metrics[element].description].divisor] )
        if(number!== ((dividend/divisor)*100).toFixed(2) & isFinite(((dividend/divisor)*100).toFixed(2)) === true){
          let amount = ((dividend/divisor)*100).toFixed(2)
          let index = switchCalc.indexOf(metrics[element].description)
          if(index >= 0){
            if (amount !== calcSwitchAmount[index] ){
              console.log(backSpacedCalc)
              let updatedList = [...backSpacedCalc]
              updatedList = updatedList.filter(item => item !== metrics[element].description)
              setBackSpacedCalc(updatedList);
              calcMutation.mutate({desc: metrics[element].description, amount: amount})
            }   
          }
          
        }

      }
      //Break this out and compare to a use state variable keep similar logic
      if(universalCalc.includes(metrics[element].description)){
        if (universalCalcInpDict[universalAttributes[metrics[element].description].divisor] === "" || universalCalcInpDict[universalAttributes[metrics[element].description].divisor] === "0"){
            if (number !== "" && backSpacedCalc.includes(metrics[element].description) === false){
              console.log(backSpacedCalc)
              setBackSpacedCalc([...backSpacedCalc, metrics[element].description])
              calcMutation.mutate({desc: metrics[element].description, amount: ""})
            }
            
            

          
        }
      }
      if(convertCalc.includes(metrics[element].description)){
        if (universalCalcInpDict[universalAttributes[metrics[element].description].divisor] === "" || universalCalcInpDict[universalAttributes[metrics[element].description].divisor] === "0"){
            if (number !== "" && backSpacedCalc.includes(metrics[element].description) === false){
              console.log(backSpacedCalc)
              setBackSpacedCalc([...backSpacedCalc, metrics[element].description])
              calcMutation.mutate({desc: metrics[element].description, amount: ""})
            }
            

          
        }
      }
      if(switchCalc.includes(metrics[element].description)){
        let divisor = Math.max(universalCalcInpDict[universalAttributes[metrics[element].description].dividend],universalCalcInpDict[universalAttributes[metrics[element].description].divisor] )
        if (divisor === "" || divisor === "0" || divisor === 0 || isNaN(divisor) === true){
          if (number !== "" && backSpacedCalc.includes(metrics[element].description) === false){
              console.log(backSpacedCalc)
              setBackSpacedCalc([...backSpacedCalc, metrics[element].description])
              calcMutation.mutate({desc: metrics[element].description, amount: ""})
            }
          


        }
      

      }
    }

      
    
    
    //going through nonCompleteCats array and making sure the value in dictionary is updated if they aren't completed
    for (let cat of nonCompleteCats){
        categoryCompleted[cat] = "No"
    }
    
    //Code to display data based on category selection 
    if(currCategory !== 0){
        for(let element in metrics){
          let metricComp = (metrics[element].category)
        
          if(metricComp === currCategory){
            if(dispFive.includes(metrics[element].description)){
              continue
            }
            metricDict[metrics[element].description] = metrics[element].metric
            let trueFalse = (metrics[element].error === true)
            metricErrorDict[metrics[element].description] = trueFalse
            metricLastDict[metrics[element].description] = metrics[element].lastMetric
            metricHelpDict[metrics[element].description] = metrics[element].help
            metricGoalDict[metrics[element].description] = metrics[element].goal
            metricR12Dict[metrics[element].description] = metrics[element].r12m
            metricAdornDict[metrics[element].description] = metrics[element].adorn
            metricRcaDict[metrics[element].description] = metrics[element].rcaRoot
            metricRcaActionDict[metrics[element].description] = metrics[element].rcaAction
            metricDisabled[metrics[element].description] = metrics[element].disabled
            metricMultiLine[metrics[element].description] = false
            metricInfoDict[metrics[element].description] = metrics[element].info
          }
          
        }  
        
      
      }
    //keeps countMetrics from running on every render need to be located down here to get correct x value
    useEffect(()=>{
      if(z !== x){
          setNumOfMetrics(x)
          countMetrics(x)
      } 

  }, [x,z, countMetrics])
    //keeps RCA count from running on every render
    useEffect(()=>{
        if(y !== rcaCount){
        setNumOfRcas(rcaCount)
        countRCAs(rcaCount)
        } 
    
  }, [y,rcaCount, countRCAs])
  //MetricCount
  useEffect(()=>{
    if(t !== total){
    setTotalMetCount(total)
    } 

}, [t,totalMetCount, total])

    //code to map out Metric Text Fields
    const listItems = Object.keys(metricDict).map(
        (key, index) => {
          if(yesNo.includes(key)){
            return (
              <div className='dataDiv' key = {currCategory + index} style={{display:'flex', flexDirection: 'column', marginTop: 'clamp(20px, 0.5vw, 100px)'}}>
            <FormLabel component="legend">{key}:</FormLabel>
            <RadioGroup className = "yesFlex" aria-label="choice" name={key} value={metricDict[key]} row>
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" onChange={handleChange(key)} />
              <FormControlLabel value="No" control={<Radio />} label="No" onChange={handleChange(key)}/>
              <InfoIcon style={{fontSize: 'clamp(0.7rem, 1vw, 5rem)'}} className='infoIcon'/>
        <div className='hoverInfo'>`{metricInfoDict[key]}`</div>
            </RadioGroup>
              
            
        
      
              </div>
            
            )
          }else if(universalCalc.includes(key)||convertCalc.includes(key)||switchCalc.includes(key)){
            return (
              <div className='dataDiv'  key = {currCategory + index} style={{display:'flex'}}>
              <TextField sx={{fontSize: 'clamp(0.5rem, 1vw, 10rem)'}}  className={'textField'} InputLabelProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='standard'
              type="text"
              error = {metricErrorDict[key]}
          
          label={key}
          disabled = {true}
          value = {universalCalcDict[key]}
          helperText = {metricHelpDict[key]}
         
          InputProps={{ 
            endAdornment: <InputAdornment position="end">{metricAdornDict[key]}</InputAdornment>, //can pass through type of number/value
            style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}
            
          }}
          
          margin="normal"
          size='small'
        />
        <InfoIcon style={{fontSize: 'clamp(0.7rem, 1vw, 5rem)'}} className='infoIcon'/>
        <div className='hoverInfo'>`{metricInfoDict[key]}`</div>
        
        
              </div>
              
              
            )
            
      
          }
          else if(showDisabled.includes(key)){
            return (
              <div className='dataDiv'  key = {currCategory + index} style={{display:'flex'}}>
              <TextField sx={{fontSize: 'clamp(0.5rem, 1vw, 10rem)'}}  className={'textField'} InputLabelProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='standard'
              type="text"
              error = {metricErrorDict[key]}
          
          label={key}
          disabled = {true}
          defaultValue = {metricDict[key]}
          helperText = {metricHelpDict[key]}
          itemProp = {{style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          
          InputProps={{ 
            endAdornment: <div style={{fontSize: 'clamp(0.5rem, 0.5vw, 5rem)'}}> <InputAdornment sx = {{fontSize: 'clamp(0.5rem, 0.5vw, 5rem)'}} position="end">{metricAdornDict[key]}</InputAdornment> </div>, 
            //can pass through type of number/value
            style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}
            
            
          }}
          margin="normal"
          size='small'
        />
        <InfoIcon style={{fontSize: 'clamp(0.7rem, 1vw, 5rem)'}} className='infoIcon'/>
        <div className='hoverInfo'>`{metricInfoDict[key]}`</div>
        
        
              </div>
              
              
            )

          }
          else{
            return (
              <div className='dataDiv'  key = {currCategory + index} style={{display:'flex'}}>
              <TextField sx={{fontSize: 'clamp(0.5rem, 1vw, 10rem)'}}  className={'textField'} InputLabelProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='standard'
              type="text"
              error = {metricErrorDict[key]}
          
          label={key}
          disabled = {disabled}
          defaultValue = {metricDict[key]}
          helperText = {metricHelpDict[key]}
          itemProp = {{style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          //prevents user from entering anything not in the acceptable array and keeping value below 100!
          onKeyPress={(event) => {
            if (acceptable.includes(event?.key) === false || metricAdornDict[key] === '%' & parseFloat(event.target.value + event?.key) > 100 || event.target.value.includes(".") & event?.key === "." || metricAdornDict[key] === '%' & event?.key === "," ) {
              event.preventDefault();
            }
          }}
          InputProps={{ 
            endAdornment: <div style={{fontSize: 'clamp(0.5rem, 0.5vw, 5rem)'}}> <InputAdornment sx = {{fontSize: 'clamp(0.5rem, 0.5vw, 5rem)'}} position="end">{metricAdornDict[key]}</InputAdornment> </div>, 
            //can pass through type of number/value
            style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}
            
            
          }}
          onBlur={handleBlur(key)}
          margin="normal"
          size='small'
        />
        <InfoIcon style={{fontSize: 'clamp(0.7rem, 1vw, 5rem)'}} className='infoIcon'/>
        <div className='hoverInfo'>`{metricInfoDict[key]}`</div>
        
        
              </div>
              
              
            )}})
    //code to map out dictionary of category last month
    const lastListItems = Object.keys(metricLastDict).map(
        (key, index) => {
            return (
            <div className='dataDiv' key = {index} style={{display:'flex'}}>
            <TextField  className='textField' InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
            color='primary'
            variant='filled'
            error = {false}
            disabled = {true}
            helperText = " "
        
        value = {metricLastDict[key]}
        
    
        
        margin="normal"
        size='small'
        />
            </div>
        )})
    
    //code to map out goals
    const goalListItems = Object.keys(metricGoalDict).map(
        (key, index) => {
          if (lowerGoals.includes(key)){
            if((metricDict[key] === 'Yes' && metricGoalDict[key]=== 'No') || (metricDict[key] === 'No' && metricGoalDict[key] === 'Yes')){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(255,0,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )



            }
            else if((metricDict[key] === 'Yes' && metricGoalDict[key] === 'Yes') || (metricDict[key] === 'No' && metricGoalDict[key] === 'No')){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(0,128,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if(parseFloat(metricDict[key]) > parseFloat(metricGoalDict[key])){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(255,0,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if((metricDict[key] > 100 && metricGoalDict[key]!== "")){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(255, 165, 0,0.5)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if(parseFloat(metricDict[key]) <= parseFloat(metricGoalDict[key])){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(0,128,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else{
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )


            }
          }
          else{
            if((metricDict[key] === 'Yes' && metricGoalDict[key] === 'No') || (metricDict[key] === 'No' && metricGoalDict[key] === 'Yes')){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(255,0,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )



            }
            else if((metricDict[key] === 'Yes' && metricGoalDict[key] === 'Yes') || (metricDict[key] === 'No' && metricGoalDict[key] === 'No')){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(0,128,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if(parseFloat(metricDict[key]) < parseFloat(metricGoalDict[key])){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)',backgroundColor: 'rgba(255,0,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if((metricDict[key] > 100 && metricGoalDict[key]!== "")){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(255, 165, 0,0.5)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else if(parseFloat(metricDict[key]) >= parseFloat(metricGoalDict[key])){
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)', backgroundColor: 'rgba(0,128,0,0.2)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )

            }
            else{
              return(
                <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
              <TextField className='textField' InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='filled'
              error = {false}
              disabled = {true}
              helperText = " "
          
          value = {metricGoalDict[key]}

          margin="normal"
          size='small'
        />
        
              </div>

              )


            }

          }
          })      
    //code to map out goals
    const r12ListItems = Object.keys(metricR12Dict).map(
      (key, index) => {
          return (
            <div className='dataDiv'  key = {"avg" + index} style = {{display:'flex'}}>
            <TextField className='textField' InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*', style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
            color='primary'
            variant='filled'
            error = {false}
            disabled = {true}
            helperText = " "
        
        value = {metricR12Dict[key]}

        margin="normal"
        size='small'
      />
      
            </div>
      )})      

    //Code to map out RCA roots
    const rcaItems = Object.keys(metricRcaDict).map(
        (key, index) => {
            return (
              <div className='dataDiv' key = {currCategory + index + "rca"} style={{display:'flex'}}>
              <TextField   className={'textField'} 
              InputLabelProps={{style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              //onFocus = {maxRows = 2}
              
              color='primary'
              variant='standard'
              type="text"
              error = {metricErrorDict[key]}
              multiline
              maxRows={2}
          label={key}
          disabled = {metricDisabled[key]}
          defaultValue = {metricRcaDict[key]}
          helperText = {metricHelpDict[key]}
          inputProps={{ maxLength: 140, style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          
          onBlur={handleRcaBlur(key)}
          margin="normal"
          size='small'
        />
      
        
              </div>
              
            )})
      
    //Code to map out RCA Action
    const rcaItemsAction = Object.keys(metricRcaActionDict).map(
        (key, index) => {
            return (
              <div className='dataDiv' key = {currCategory + index + "rcaAction"} style={{display:'flex'}}>
              <TextField  className={'textField'} 
              InputLabelProps={{style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
              color='primary'
              variant='standard'
              type="text"
              error = {metricErrorDict[key]}
              multiline = {true}
              maxRows={2}
          label={key}
          disabled = {metricDisabled[key]}
          defaultValue = {metricRcaActionDict[key]}
          helperText = {metricHelpDict[key]}
          inputProps={{ maxLength: 140, style: {fontSize: 'clamp(0.5rem, 1vw, 5rem)'}}}
          
          
          onBlur={handleRcaActionBlur(key)}
          margin="normal"
          size='small'
        />
      
        
              </div>
              
              
            )})
    
    
//Capture if query is loading and metrics should not render yet
    if(dbQuery.isLoading) return <div className='loadWalk'>
      <NewSideCube
       front={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       back={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       left={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       right={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       top={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       bottom={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
     />
    </div>
    if(dbQuery.isError) return <pre className='redError'>Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a></pre>



//RETURN OF JSX ELEMENTS THAT ACTUALLY RENDER NEw
    return (
      <>
      {isFirstDay && isOpen && newMetrics.length === 0 && (
       <div className="overlayNew">
          <button className="close-buttonNew" onClick={handleClose}>X</button>
          <h3>Metrics for the new month entry will be available after Midnight Eastern Standard Time on the 1st of the month. Please check back then, if no categories display after loading is complete!</h3>
        </div>
      )}
      <h4 className='dateFixMet'>{dateDict[month]} {currYear}</h4>
      

        <div className='metricLoader'>
        <div className="labsNav1">
        <div className="logoContain">
          
          <Link className='linkFlex' to = "/">
              <h1>C O T Y</h1>
              </Link>
          </div>
          
  
          <div className='help' >
            
            <div className='muiButtons'>
              <div className='homeMui'><a href='https://metrics.logistics.coty.com' title='Home'><HomeIcon/></a></div>
              <div className='change'><a href='https://forms.office.com/r/PqdttMbugi' target="_blank" and rel="noopener noreferrer" title='ChangeRequest'><ArticleIcon/></a> </div>
              <div className='contactUs' onClick={(e) => {window.location.href ='mailto:Logistics_Data@cotyinc.com?subject=KPI%20Metric%20Request';}} title='Contact Us'><HelpIcon/></div>
              </div>
            </div>
        </div>
        <div className="stoplight-container">
      <div
        className="stoplight-light"
        style={{ backgroundColor: colors[currentColorIndex] }}
      >
        <div className="stoplight-label">
          {currentColorIndex === 0 && `${(((totalMetCount-numOfMetrics)/totalMetCount)*100).toFixed(2)}% Complete`}
          {currentColorIndex === 1 && `Incomplete RCAS ${numOfRcas}`}
          {currentColorIndex === 2 && `Metrics Left ${numOfMetrics}`}
        </div>
      </div>
    </div>
    <div className="cubeLeftTwo">
      <NewSideCube
       front={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>Actionable Data</div>
           <DatasetIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       back={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>Fast Processing</div>
          <SpeedIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       left={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>Optimal Decisions</div>
           <PendingActionsIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       right={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>Increased Efficiency</div>
           <ThumbUpOffAltIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       top={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>Real Time Results</div>
          <AccessTimeIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       bottom={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>Easy to Use</div>
          <ElectricBoltIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
     />

      </div>
       
          
        

        <div className='loadMets'>
          <div>
        
          </div>
          <div className='formHeaderSect'>
          <div className='arrowFlex'><div className='metricLeft' style={{color: numOfMetrics > 0? 'rgba(255, 0, 0, 0.7)': 'rgba(0, 37, 84, 1)'}}></div> <div className='plantName'><div className='centerThis'><h1>{plantDictionary[plant] && plantDictionary[plant].toUpperCase()}</h1></div></div> <div className='rcaIncomplete' style={{color: numOfRcas > 0? 'rgba(255, 0, 0, 0.7)': 'rgba(0, 37, 84, 1)'}}></div> 
          </div>
          <div className='flexCatTwo' style={{opacity: plant === 'none'? '0': '1'}}>
          <ArrowCircleLeftIcon className='arrow' onClick={()=>moveCat("minus")}/>
        {categoryArray.map((category, i)=>(
          <div key = {i} className='categoryTwo' style={{color: categoryCompleted[category] === 'Yes'? 'rgba(0, 128, 0, 0.7)': 'rgba(0, 37, 84, 0.9)',  border: currCategory === (category)? '1px solid #002254': 'none'}} onClick={()=>updateCat({category})}>
          {mui[category]} <div className='overlay'>{category}</div>
          <div className='overlay'>{totalMetsCat[category]-(missingMetsCat[category]||0)}/{totalMetsCat[category]}</div>
          </div>
          

        ))}
        <ArrowCircleRightIcon className='arrow' onClick={()=>moveCat("plus")}/>


        </div>
          </div>
         
        
        

        <div className='flexForms' ref={containerRef}>
        <div className='metricList'>
          <form className='formFlex' autoComplete='off' style = {{display: postMutation.status === 'error' || calcMutation.status === 'error'? 'none': 'flex'}}>
          <div className='flexDiv'><h5 className='monthLabels' style={{display: currCategory === 0? 'none': 'flex'}}>Enter Metrics</h5></div>
          {listItems}
            </form>
            <h4 className='errorRed' style = {{display: postMutation.status === 'error' || calcMutation.status === 'error' ? 'block': 'none'}} >Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a></h4>
            </div>
            <div className='metricList combo'>
          <form className='formFlex third prevMth' autoComplete='off'>
            <div className='flexDiv monthLabels prevMth'><h5 style={{display: currCategory === 0? 'none': 'flex'}}>PrvMth</h5></div>
          
          {lastListItems}
            </form>
            <form className='formFlex third goal' autoComplete='off'>
            <div className='flexDiv monthLabels goal'><h5 style={{display: currCategory === 0? 'none': 'flex'}}>Goal</h5></div>
          
          {goalListItems}
            </form>
            <form className='formFlex third goal' autoComplete='off'>
            <div className='flexDiv monthLabels goal'><h5 style={{display: currCategory === 0? 'none': 'flex'}}>12MthAvg</h5></div>
          
          {r12ListItems}
            </form>
            </div>
            <div className='metricList'>
          <form className='formFlex' autoComplete='off' style = {{display: rcaMutation.status === 'error'? 'none': 'flex'}} >
         
            <div className='flexDiv monthLabels'><h5 style={{display: currCategory === 0? 'none': 'flex'}}>Root Cause</h5></div>
          
          {rcaItems}
            </form>
            <h4 className = 'errorRed' style = {{display: rcaMutation.status === 'error' ? 'block': 'none'}} >Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a></h4>
            </div>

            <div className='metricList'>
          <form className='formFlex' autoComplete='off' style = {{display: rcaActionMutation.status === 'error'? 'none': 'flex'}} >
         
            <div className='flexDiv monthLabels'><h5 style={{display: currCategory === 0? 'none': 'flex'}}>Action Plan</h5></div>
          
          {rcaItemsAction}
            </form>
            <h4 className='errorRed' style = {{display: rcaActionMutation.status === 'error'? 'block': 'none'}} >Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a></h4>
            </div>
            

        </div>
        
        
          
            </div>
            
            

      </div>
      </>

    )

    

}

export default MetricLoader